





































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import ExportButton from "@/components/ExportButton/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";
import { ActivityDto, ActivityApplyDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditApply from "../apply/edit.vue";
import PagedTabsTableViewForActivityApply from "@/components/PagedTableView/PagedTabsTableViewForActivityApply.vue";

@Component({
  name: "ActivityApplyList",
  components: {
    PagedTabsTableViewForActivityApply,
    PagedTableView,
    ExportButton,
    EditApply,
    ImportExcel,
  },
})
export default class ActivityApplyList extends Vue {
  @Ref() editForm!: EditApply;

  created() {
    if (this.$route.params.id) {
      this.queryForm.activityId = Number(this.$route.params.id);
    }

    api.enumService
      .getValues({ typeName: "ActivityApplyStatus" })
      .then((res: any) => {
        this.applyStatusList = res;
      });
    this.reload();
  }

  reload() {
    api.activity.get({ id: this.queryForm.activityId }).then((res) => {
      this.detail = res;
    });
  }

  applyStatusList: any[] = [];

  queryForm = {
    applyUserName: "",
    applyUserPhone: "",
    activityTitle: "",
    status: "",
    activityId: 0,
  };

  detail: ActivityDto = {};

  id = 0;

  // 获取表数据
  fetchData(params: any) {
    params = Object.assign({}, params);
    console.log("test:", params);
    return api.activityApply.getCheckIn(params);
  }

  // 签到
  handleCheckIn(index: number, row: ActivityApplyDto) {
    this.$confirm("你确定手动签到吗?", "提示").then(async () => {
      api.activityApply
        .checkIn({
          body: {
            id: row!.id,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "签到成功",
          });
          this.fetchData(this.queryForm);
          this.reload();
        });
    });
  }

  //提交审核
  handleAudit(index: number, row: ActivityApplyDto) {
    api.activityApply.postToAudit({ body: { id: row.id } }).then(() => {
      this.fetchData(this.queryForm);
    });
  }

  // 删除
  async handleDelete(index: number, row: ActivityApplyDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.activity
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleCreate() {
    this.editForm.show = true;
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }

  cancel() {
    this.$router.go(-1);
  }
}
