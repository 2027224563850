var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.show,"close-on-click-modal":false,"modal":true},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.cancel}},[_c('el-form',{ref:"dataForm",attrs:{"rules":_vm.linkRule,"model":_vm.form,"label-position":"top"}},[(_vm.activity)?[_c('el-form-item',{attrs:{"label":"活动标题"}},[_c('el-input',{attrs:{"value":_vm.activity.title,"disabled":""}})],1),_c('el-form-item',{attrs:{"label":"活动类型"}},[_c('el-input',{attrs:{"value":_vm.activity.typeID === 'GeneralActivity' ? '普通活动' : '志愿者活动',"disabled":""}})],1)]:_vm._e(),(_vm.activity.typeID === 'GeneralActivity')?[_c('el-form-item',{attrs:{"label":"报名人姓名","prop":"surname","required":""}},[_c('el-input',{model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1),_c('el-form-item',{attrs:{"label":"报名人手机","prop":"phoneNumber","rules":[
          {
            required: true,
            pattern: /^1[345789]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          } ]}},[_c('el-input',{model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1)]:[(_vm.form.userId)?[_c('el-form-item',{attrs:{"label":"志愿者姓名","prop":"surname"}},[_c('el-input',{attrs:{"value":_vm.form.surname,"disabled":""}})],1),_c('el-form-item',{attrs:{"label":"志愿者手机","prop":"phoneNumber","rules":[
            {
              required: true,
              pattern: /^1[345789]\d{9}$/,
              message: '请输入正确的手机号',
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"value":_vm.form.phoneNumber,"disabled":""}})],1)]:_vm._e(),_c('el-button',{on:{"click":_vm.selectVolunteer}},[_vm._v("选择志愿者")])]],2),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.btnDisabled},on:{"click":_vm.save}},[_vm._v("确定")])],1),_c('VolunteerSelect',{ref:"selection",attrs:{"single":true},on:{"select":_vm.onSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }