








































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import api from "@/api";
import { VolunteerDto } from "../../api/appService";
import PagedTableViewSimple from "@/components/PagedTableViewSimple/index.vue";
@Component({
  components: { PagedTableViewSimple },
})
export default class UserSelect extends Vue {
  @Prop({ required: false, default: false }) single!: boolean;
  public show = false;

  queryForm: any = {
    volunteerName: "",
    volunteerPhone: "",
    volunteerCode: "",
  };

  selection: VolunteerDto[] = [];

  async handleSelectionChange(e: any) {
    console.log(e);
    this.selection = e;
  }

  fetchData(params: any) {
    return api.volunteer.getAll(params);
  }

  async handleSearch() {
    (this.$refs.pagedTableOfUser as any).refreshData();
  }

  async select(user: VolunteerDto) {
    this.$emit("select", user);
    this.show = false;
  }

  async handleSave_users() {
    // console.log(this.selection);
    this.$emit("select", this.selection);
    this.show = false;
  }

  handleResetSearch() {
    if (this.queryForm) {
      for (let key in this.queryForm) {
        this.queryForm[key] = undefined;
      }
    }
    this.fetchData(this.queryForm).then((res) => {
      let f = this.$refs.pagedTableOfUser as any;

      f.tableItems = res.items!;
      f.table.totalCount = res.totalCount!;
    });
  }
}
