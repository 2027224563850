






























import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import {ActivityApplyCheckInPostDto} from "@/api/appService";
import api from "@/api";

@Component({
  name: "ManualCheckIn",
})

export default class ManualCheckIn extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: ActivityApplyCheckInPostDto = {
    id: undefined,
    checkInTime: undefined
  };

  show = false;
  form: ActivityApplyCheckInPostDto = {...this.defaultData};

  get title() {
    return "签到";
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.activityApply.checkIn(
          {
            body: {
              id: this.form.id,
              checkInTime: this.form.checkInTime,
            }
          });

        // if (this.dataId) {
        //   await api.userGroup.update({
        //     body: {...this.form},
        //   });
        // } else {
        //   await api.userGroup.create({
        //     body: {...this.form},
        //   });
        // }

        this.show = false;
        this.$message.success("签到成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  formRule = {
    checkInTime: [
      {
        required: true,
        message: "请选择签到时间",
        trigger: "change",
      },
    ],
  };
}
