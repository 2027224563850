
























































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import api from "@/api";
import { ActivityApplyCreateOrUpdateDto, VolunteerDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import VolunteerSelect from "@/components/VolunteerSelect/index.vue";

@Component({
  name: "EditApply",
  components: { VolunteerSelect },
})
export default class EditApply extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly selection!: VolunteerSelect;
  @Prop({ required: true })
  activityId!: number;

  @Prop({ required: false })
  activity!: any;

  defaultData: ActivityApplyCreateOrUpdateDto = {
    id: 0,
  };

  show = false;
  form: ActivityApplyCreateOrUpdateDto = { ...this.defaultData };
  btnDisabled = false;

  get title() {
    if (this.dataId) {
      return `编辑报名信息 ${this.form.surname}`;
    } else {
      return "新建报名";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    console.log("activityId:", this.activityId);
    if (value) {
      await api.activityApply.getForEdit({ id: this.form!.id }).then((res) => {
        this.form = res.data!;
        this.form.activityId = this.activityId;
      });
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.activityApply.update({
            body: { ...this.form },
          });
        } else {
          await api.activityApply.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
        this.btnDisabled = false;
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.show = false;
  }

  selectVolunteer() {
    this.selection.show = true;
  }

  async onSelect(user: VolunteerDto) {
    console.log(user);
    this.form!.userId = user.userId;
    this.form!.surname = user.user?.surname;
    this.form!.phoneNumber = user.user?.phoneNumber;
  }

  linkRule = {
    surname: [
      {
        required: true,
        message: "请输入姓名",
        trigger: "blur",
      },
    ],
    phoneNumber: [
      {
        required: true,
        message: "请输入电话",
        trigger: "blur",
      },
    ],
  };
}
