



























































































import PagedTableView from "./index.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "PagedTabsTableView",
})
export default class PagedTabsTableView extends PagedTableView {
  @Prop({ required: false, default: "table" })
  activeTab: any;

  handleClick(tab: any, event: any) {
    if (tab.index.toString() == "0") {
      this.$router.push(`/activity/activity/checkIn/${this.detailId}`);
    } else {
      this.$router.push(`/activity/activity/checkInRecord/${this.detailId}`);
    }
  }
}
